@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-body;
  }

  .text-headline {
    @apply font-title text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold;
    text-wrap: balance;
  }

  .text-heading-1 {
    @apply font-title text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold;
    text-wrap: balance;
  }

  .text-heading-2 {
    @apply font-title text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold;
    text-wrap: balance;
  }

  .text-heading-3 {
    @apply font-title text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold;
    text-wrap: balance;
  }

  .text-heading-4 {
    @apply font-title text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium;
    text-wrap: balance;
  }

  .text-heading-5 {
    @apply font-title text-base sm:text-lg md:text-xl lg:text-2xl font-medium;
    text-wrap: balance;
  }

  .text-lede {
    @apply font-body text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl;
    text-wrap: balance;
  }

  .text-eyebrow {
    @apply font-pixel text-sm sm:text-base lg:text-lg;
  }

  .text-1 {
    @apply text-light-text-1 dark:text-dark-text-1;
  }

  .text-2 {
    @apply text-light-text-2 dark:text-dark-text-2;
  }

  .text-3 {
    @apply text-light-text-3 dark:text-dark-text-3;
  }

  .text-4 {
    @apply text-light-text-4 dark:text-dark-text-4;
  }

  .text-5 {
    @apply text-light-text-5 dark:text-dark-text-5;
  }

  .prose {
    @apply text-2;
  }

  .prose strong {
    font-weight: 700;
    color: inherit;
  }

  .prose h1,
  .prose h2,
  .prose h3,
  .prose h4,
  .prose h5,
  .prose h6 {
    @apply mt-0 text-1;
  }

  .prose h1,
  .prose h2 {
    @apply text-heading-4 font-medium;
  }

  .prose h3 {
    @apply text-heading-5;
  }

  .prose p {
    @apply mt-0;
  }

  .prose ol,
  .prose ul {
    @apply mb-[1.25em] md:mb-[1.33em];
  }

  .prose li {
    @apply mt-0 mb-[1em];
  }

  .prose li * {
    @apply mt-0 mb-0;
  }

  .prose a {
    @apply font-medium text-brand-500 hover:text-brand-300 dark:text-brand-300 dark:hover:text-brand-100 no-underline hover:underline cursor-pointer;
  }
}

@layer components {
  .prose :where(code):not(:where([class~="not-prose"] *)) {
    @apply bg-black font-code text-light-100 font-normal px-2;
  }

  .prose :where(pre):not(:where([class~="not-prose"] *)) {
    @apply font-code text-light-100 font-normal mt-0 ml-0 mr-0 mb-0;
  }

  .prose :where(code):not(:where([class~="not-prose"] *))::before,
  .prose :where(code):not(:where([class~="not-prose"] *))::after {
    content: "" !important;
  }
}
